<template>
    <div class="roadFront">
        <el-container class="ova-y">
            <el-main class="p0">
                <el-row>
                    <el-col :span="24">
                        <div class="box" ref="init">
                            <div class="box-content">
                                <el-form :inline="true" class="form-inline formBox">
                                    <el-form-item label="行政区划" v-if="roleName !== '干线公路'">
                                        <el-cascader :key="parseInt(Math.random()*10000)" style="width: 160px" size="medium" v-model="region" :options="regions" :props="{ checkStrictly: true, expandTrigger: 'hover' }" clearable ></el-cascader>
                                    </el-form-item>
                                    <el-form-item label="项目名称">
                                        <el-input style="width: 160px" size="medium" v-model="xmmc" placeholder="请输入" clearable></el-input>
                                    </el-form-item>
<!--                                    <el-form-item label="项目法人单位">-->
<!--                                        <el-input style="width: 160px" size="medium" v-model="frdw" placeholder="请输入"></el-input>-->
<!--                                    </el-form-item>-->
                                    <el-form-item label="项目类别">
                                        <el-cascader size="medium" style="width: 160px" v-model="checkedXmlb" :options="xmlbList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="技术等级">
                                        <el-cascader size="medium" style="width: 160px" v-model="checkedJsgm" :options="jsgmList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
<!--                                    <el-form-item label="项目性质">-->
<!--                                        <el-cascader size="medium" style="width: 160px" v-model="checkedXmxz" :options="xmxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>-->
<!--                                        </el-cascader>-->
<!--                                    </el-form-item>-->
                                    <el-form-item label="建设性质">
                                        <el-cascader size="medium" style="width: 160px" v-model="checkedJsxz" :options="jsxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
<!--                                    <el-form-item  label="分类">-->
<!--                                        <el-cascader size="medium" style="width: 180px" v-model="checkedLabel" :options="labelList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>-->
<!--                                        </el-cascader>-->
<!--                                    </el-form-item>-->
                                    <el-form-item>
                                        <el-button type="primary"  style="margin-top:19px"  size="medium" icon="el-icon-search" @click="currentPage = 1;search();">查询</el-button>
                                        <el-button type="primary" size="medium" plain @click="clearCondition();search();" icon="el-icon-delete">清除</el-button>
                                    </el-form-item>

                                </el-form>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="plr12">
                    <el-col :span="24">
                        <div class="box">
                            <div class="box-title">
<!--                                <span class="fright">-->
<!--                                    <el-button @click="exportData1" type="primary" size="mini" round icon="el-icon-upload2">前期进展统计导出</el-button>-->
<!--                                    <el-button @click="exportData" type="primary" size="mini" round icon="el-icon-upload2">导出</el-button>-->
<!--                                    <el-button v-if="isAdmin" @click="addRoad" type="primary" size="mini" round icon="el-icon-plus">新增</el-button>-->
<!--                                </span>-->
                                <h3 class="tit">公路列表</h3>
                            </div>
                            <div class="box-content">
<!--                                <div class="tj">-->
<!--                                    <div class="total-item total-item-1">-->
<!--                                        <img src="../../assets/icon/tj1.png" alt="">-->
<!--                                        <div class="t">-->
<!--                                            <h5>项目数量<small>（个）</small></h5>-->
<!--                                            <b class="date-num">{{ xmsl }}</b>-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div class="total-item total-item-2">-->
<!--                                        <img src="../../assets/icon/tj2.png" alt="">-->
<!--                                        <div class="t">-->
<!--                                            <h5>建设规模-里程<small>（公里）</small></h5>-->
<!--                                            <b class="date-num">{{ jsgm }}</b>-->
<!--                                        </div>-->
<!--                                    </div>-->


<!--                                    <div class="total-item total-item-4">-->
<!--                                        <img src="../../assets/icon/tj4.png" alt="">-->
<!--                                        <div class="t">-->
<!--                                            <h5>总投资<small>（万元）</small></h5>-->
<!--                                            <b class="date-num">{{thousandBitSeparator(ztz.toFixed(0) )}}</b>-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                </div>-->

                                <el-tabs v-model="activeRoad" @tab-click="changeRoad">
                                    <el-tab-pane label="国家高速公路" name="21"></el-tab-pane>
                                    <el-tab-pane label="普通国道" name="22"></el-tab-pane>
                                    <el-tab-pane label="省级高速公路" name="23"></el-tab-pane>
                                    <el-tab-pane label="普通省道" name="24"></el-tab-pane>
                                    <el-tab-pane label="其他" name="其他"></el-tab-pane>
                                </el-tabs>
                                <el-table  @sort-change="changeSort" @row-dblclick="openDetailsEdit" ref="table" :data="tableData" size="small " :height="tableHeight" border  style="width: 100%">
                                    <el-table-column fixed prop="" align="center"  label="操作">
                                        <template slot-scope="scope">
                                            <span class="newicon iconsyes" @click="openDetailsEdit(scope.row)"><i class="el-icon-edit-outline icons iconsyes"></i>详情</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column fixed prop="XMBM" label="项目编码" :width="100" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column fixed prop="XMMC" label="项目名称" :width="280" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column fixed prop="SFMC" label="省份名称" :width="150" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column fixed prop="XZQHDM" label="行政区划代码" show-overflow-tooltip :width="120" sortable></el-table-column>
                                    <el-table-column prop="LDQDMC" label="路段起点名称" :width="150" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="LDZDMC" label="路段止点名称" :width="150" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="XMLB" label="项目类别" :width="150" sortable show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <div v-if="scope.row.XMLB ==21">国家高速公路</div>
                                            <div v-if="scope.row.XMLB ==22">普通国道</div>
                                            <div v-if="scope.row.XMLB ==23">省级高速公路</div>
                                            <div v-if="scope.row.XMLB ==24">普通省道</div>
                                            <div v-if="scope.row.XMLB ==25">县道</div>
                                            <div v-if="scope.row.XMLB ==26">乡道</div>
                                            <div v-if="scope.row.XMLB ==27">村道</div>
                                            <div v-if="scope.row.XMLB ==31">航道</div>
                                            <div v-if="scope.row.XMLB ==32">码头</div>
                                            <div v-if="scope.row.XMLB ==33">防波堤</div>
                                            <div v-if="scope.row.XMLB ==34">锚地</div>
                                            <div v-if="scope.row.XMLB ==35">通航建筑及枢纽</div>
                                            <div v-if="scope.row.XMLB ==90">其他项目</div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="LXBH" label="路线编号" :width="150" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="JSXZ" label="建设性质" :width="150" sortable show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <div v-if="scope.row.JSXZ ==10">新建</div>
                                            <div v-if="scope.row.JSXZ ==20">高速公路扩容</div>
                                            <div v-if="scope.row.JSXZ ==30">升级改造</div>
                                            <div v-if="scope.row.JSXZ ==40">原级改造</div>
                                            <div v-if="scope.row.JSXZ ==50">路面改造</div>
                                            <div v-if="scope.row.JSXZ ==90">其他</div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="LC" label="里程(米)" align="right" :width="100" sortable show-overflow-tooltip>
                                        <template slot-scope="scope">
                                        {{scope.row.LC ? thousandBitSeparator(scope.row.LC.toFixed(0) ): ''}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="KGN" label="开工年" align="center" :width="100" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="WGN" label="完工年" align="center" :width="100" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="CDS" label="车道数" align="center" :width="100" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="JSDJ" label="技术等级" :width="100" align="center" sortable show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <div v-if="scope.row.JSDJ ==10">高速</div>
                                            <div v-if="scope.row.JSDJ ==20">一级</div>
                                            <div v-if="scope.row.JSDJ ==30">二级</div>
                                            <div v-if="scope.row.JSDJ ==40">三级</div>
                                            <div v-if="scope.row.JSDJ ==50">四级</div>
                                            <div v-if="scope.row.JSDJ ==60">等外</div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="YDGM" label="用地规模" align="right"  sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="XZYDGM" label="新增用地规模" align="right"  sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="XZZYGDGM" label="新增占用耕地规模"  align="right" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="XZZYQTNYDGM" label="新增占用其他农用地规模" align="right" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="XZZYJSYDGM" label="新增占用建设用地规模" align="right" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="XZZYWLYDGM" label="新增占用未利用地规模" align="right" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="ZXXLCPY" label="中心线两侧偏移"  align="center" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="SJSCYJ" label="数据生成依据" align="center" :width="150" sortable show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <div v-if="scope.row.SJSCYJ ==10">公路网布局规划</div>
                                            <div v-if="scope.row.SJSCYJ ==20">公路网线位规划</div>
                                            <div v-if="scope.row.SJSCYJ ==30">港口总体规划</div>
                                            <div v-if="scope.row.SJSCYJ ==40">预可行性研究报告</div>
                                            <div v-if="scope.row.SJSCYJ ==50">工程可行性研究深度的建设方案研究</div>
                                            <div v-if="scope.row.SJSCYJ ==60">最新工程可行性研究推荐方案</div>
                                            <div v-if="scope.row.SJSCYJ ==70">最新初步设计推荐方案</div>
                                            <div v-if="scope.row.SJSCYJ ==71">批复的初步设计</div>
                                            <div v-if="scope.row.SJSCYJ ==80">最新施工图设计推荐方案</div>
                                            <div v-if="scope.row.SJSCYJ ==81">批复施工图设计</div>
                                            <div v-if="scope.row.SJSCYJ ==90">其他</div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="ZTZ" label="总投资" align="right" :width="100" sortable show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            {{scope.row.ZTZ ? thousandBitSeparator(scope.row.ZTZ.toFixed(0) ): ''}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="BZ" label="备注" :width="150" sortable show-overflow-tooltip></el-table-column>
                                </el-table>
                                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
        <div class="editWrap">
            <!--            详情弹窗-->
            <el-drawer :visible.sync="showAddModel" @close="closeDrawer" size="1183px" :title="detail.XMMC"  class="editModal">
                <el-form :disabled="isLock"  :model="detail" :inline-message="true" ref="detailForm"  :inline="true" class="form-inline" v-loading="uploadLoading">
                    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                        <el-tab-pane label="基本信息" name="0">
                            <div class="col">
                                <div class="col-1">
                                    <el-form-item prop="XMBM" label="项目编码" :rules="[ { required: true, validator: (rule, value, callback)=>{checkNull(rule, value, callback)}, trigger: 'blur' }]">
                                        <el-input disabled  v-model="detail.XMBM"></el-input>
                                    </el-form-item>
                                    <el-form-item prop="SFMC" label="省份名称" :rules="[ { required: true, validator: (rule, value, callback)=>{checkNull(rule, value, callback)}, trigger: 'blur' }]">
                                        <el-input placeholder="请输入项目名称" v-model="detail.SFMC"></el-input>
                                    </el-form-item>
                                    <el-form-item prop="XMMC" label="项目名称" :rules="[ { required: true, validator: (rule, value, callback)=>{checkNull(rule, value, callback)}, trigger: 'blur' }]">
                                        <el-input placeholder="请输入项目名称" v-model="detail.XMMC"></el-input>
                                    </el-form-item>
                                    <el-form-item prop="XZQHDM" label="行政区划代码" :rules="[ { required: true, validator: (rule, value, callback)=>{checkNull(rule, value, callback)}, trigger: 'blur' }]">
                                        <el-input placeholder="行政区划代码" v-model="detail.XZQHDM"></el-input>
                                    </el-form-item>
                                    <el-form-item label="路段起点名称" prop="LDQDMC" :rules="[ { required: true, validator: (rule, value, callback)=>{checkNull(rule, value, callback)}, trigger: 'blur' }]">
                                        <el-input placeholder="请输入路段起点名称" v-model="detail.LDQDMC"></el-input>
                                    </el-form-item>
                                    <el-form-item label="路段止点名称" prop="LDZDMC" :rules="[ { required: true, validator: (rule, value, callback)=>{checkNull(rule, value, callback)}, trigger: 'blur' }]">
                                        <el-input placeholder="请输入路段止点名称" v-model="detail.LDZDMC"></el-input>
                                    </el-form-item>
                                    <el-form-item label="项目类别" prop="XMLB" :rules="[ { required: true, validator: (rule, value, callback)=>{checkNull(rule, value, callback)}, trigger: 'blur' }]">
                                        <el-select  placeholder="项目类别" v-model="detail.XMLB">
                                            <el-option label="国家高速公路" value="21"></el-option>
                                            <el-option label="普通国道" value="22"></el-option>
                                            <el-option label="省级高速公路" value="23"></el-option>
                                            <el-option label="普通省道" value="24"></el-option>
                                            <el-option label="县道" value="25"></el-option>
                                            <el-option label="乡道" value="26"></el-option>
                                            <el-option label="村道" value="27"></el-option>
                                            <el-option label="航道" value="31"></el-option>
                                            <el-option label="码头" value="32"></el-option>
                                            <el-option label="防波堤" value="33"></el-option>
                                            <el-option label="锚地" value="34"></el-option>
                                            <el-option label="通航建筑及枢纽" value="35"></el-option>
                                            <el-option label="其他项目" value="90"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="路线编号" prop="LXBH" :rules="[ { required: true, validator: (rule, value, callback)=>{checkNull(rule, value, callback)}, trigger: 'blur' }]">
                                        <el-input placeholder="路线编号" v-model.number="detail.LXBH"></el-input>
                                    </el-form-item>
                                    <el-form-item label="建设性质" :rules="[ { required: true, validator: (rule, value, callback)=>{checkNull(rule, value, callback)}, trigger: 'blur' }]">
                                        <el-select placeholder="建设性质" v-model="detail.JSXZ">
                                            <el-option label="新建" :value="'10'"></el-option>
                                            <el-option label="高速公路扩容" :value="'20'"></el-option>
                                            <el-option label="升级改造" :value="'30'"></el-option>
                                            <el-option label="原级改造" :value="'40'"></el-option>
                                            <el-option label="路面改造" :value="'50'"></el-option>
                                            <el-option label="其他" :value="'90'"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="里程"  prop="LC"  :rules="[{ required: true, validator: (rule, value, callback)=>{checkZZS(rule, value, callback)},trigger: 'blur' }]">
                                        <el-input placeholder="里程" v-model.number="detail.LC"><template #suffix>米</template></el-input>
                                    </el-form-item>

                                </div>
                                <div class="col-1">
                                    <el-form-item label="开工年" :rules="[ { required: true, validator: (rule, value, callback)=>{checkNull(rule, value, callback)}, trigger: 'blur' }]">
                                        <el-date-picker
                                                style="width: 330px"
                                                format="yyyy"
                                                value-format="yyyy"
                                                v-model="detail.KGN" type="year" placeholder="选择日期">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item label="完工年" :rules="[ { required: true, validator: (rule, value, callback)=>{checkNull(rule, value, callback)}, trigger: 'blur' }]">
                                        <el-date-picker  style="width: 330px"
                                                         format="yyyy"
                                                         value-format="yyyy"
                                                         v-model="detail.WGN" type="year" placeholder="选择日期">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item label="车道数"  prop="CDS"  :rules="[{ required: true, validator: (rule, value, callback)=>{checkZZS(rule, value, callback)},trigger: 'blur' }]">
                                        <el-input placeholder="车道数" v-model="detail.CDS"><template #suffix>个</template></el-input>
                                    </el-form-item>
                                    <el-form-item label="技术等级" :rules="[ { required: true, validator: (rule, value, callback)=>{checkNull(rule, value, callback)}, trigger: 'blur' }]">
                                        <el-select placeholder="技术等级" v-model="detail.JSDJ">
                                            <el-option label="高速" :value="'10'"></el-option>
                                            <el-option label="一级" :value="'20'"></el-option>
                                            <el-option label="二级" :value="'30'"></el-option>
                                            <el-option label="三级" :value="'40'"></el-option>
                                            <el-option label="四级" :value="'50'"></el-option>
                                            <el-option label="等外" :value="'60'"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="用地规模"  prop="YDGM" :width="120"  :rules="[{ required: true, validator: (rule, value, callback)=>{checkZZS(rule, value, callback)},trigger: 'blur' }]">
                                        <el-input placeholder="用地规模" v-model="detail.YDGM"><template #suffix>平方米</template></el-input>
                                    </el-form-item>
                                    <el-form-item label="新增用地规模" :width="120"  prop="XZYDGM"  :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                        <el-input placeholder="新增用地规模" v-model="detail.XZYDGM"><template #suffix>平方米</template></el-input>
                                    </el-form-item>
                                    <el-form-item label="新增占用耕地规模" :width="120" prop="XZZYDGM"  :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                        <el-input placeholder="新增占用耕地规模" v-model="detail.XZZYDGM"><template #suffix>平方米</template></el-input>
                                    </el-form-item>
                                    <el-form-item label="新增占用其他农用地规模" :width="120"  prop="XZZYQTNYDGM"  :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                        <el-input placeholder="新增占用其他农用地规模" v-model="detail.XZZYQTNYDGM"><template #suffix>平方米</template></el-input>
                                    </el-form-item>
                                    <el-form-item label="新增占用建设用地规模" :width="120" prop="XZZYJSYDGM"  :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                        <el-input placeholder="新增占用建设用地规模" v-model="detail.XZZYJSYDGM"><template #suffix>平方米</template></el-input>
                                    </el-form-item>
                                    <el-form-item label="新增占用未利用地规模" :width="120" prop="XZZYWLYDGM"  :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                        <el-input placeholder="新增占用未利用地规模" v-model="detail.XZZYWLYDGM"><template #suffix>平方米</template></el-input>
                                    </el-form-item>

                                </div>
                                <div class="col-1">
                                    <el-form-item label="中心线两侧偏移"  prop="ZXXLCPY"  :rules="[{ required: true, validator: (rule, value, callback)=>{checkZZS(rule, value, callback)},trigger: 'blur' }]">
                                        <el-input placeholder="中心线两侧偏移" v-model="detail.ZXXLCPY"><template #suffix>米</template></el-input>
                                    </el-form-item>
                                    <el-form-item label="数据生成依据"  prop="SJSCYJ"  :rules="[{ required: true, validator: (rule, value, callback)=>{checkNull(rule, value, callback)},trigger: 'blur' }]">
                                        <el-select placeholder="数据生成依据" v-model="detail.SJSCYJ">
                                            <el-option label="公路网布局规划" :value="'10'"></el-option>
                                            <el-option label="公路网线位规划" :value="'20'"></el-option>
                                            <el-option label="港口总体规划" :value="'30'"></el-option>
                                            <el-option label="预可行性研究报告" :value="'40'"></el-option>
                                            <el-option label="工程可行性研究深度的建设方案研究" :value="'50'"></el-option>
                                            <el-option label="最新工程可行性研究推荐方案" :value="'60'"></el-option>
                                            <el-option label="最新初步设计推荐方案" :value="'70'"></el-option>
                                            <el-option label="最新施工图设计推荐方案" :value="'80'"></el-option>
                                            <el-option label="批复施工图设计" :value="'81'"></el-option>
                                            <el-option label="其他" :value="'90'"></el-option>
                                        </el-select>
                                    </el-form-item>

                                    <el-form-item label="总投资"  prop="ZTZ"  :rules="[{ required: true, validator: (rule, value, callback)=>{checkZZS(rule, value, callback)},trigger: 'blur' }]">
                                        <el-input placeholder="总投资" v-model="detail.ZTZ"><template #suffix>万元</template></el-input>
                                    </el-form-item>
                                    <el-form-item  label="备注">
                                        <el-input autosize style="width: 340px" placeholder="请输入前期工作进展" type="textarea" v-model="detail.BZ"></el-input>
                                    </el-form-item>
                                </div>
                            </div>
                            <el-row>
                            </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="旧路信息" name="1">

                        </el-tab-pane>
                        <el-tab-pane label="建设规模" name="2">

                        </el-tab-pane>
                        <el-tab-pane label="项目建设占用新增永久用地面积" name="3">
                        </el-tab-pane>
                        <el-tab-pane label="前期流程" name="4" v-if="!addStatus">
                        </el-tab-pane>
                        <el-tab-pane label="附件" name="5" v-if="!addStatus">
                        </el-tab-pane>
                    </el-tabs>
                    <div class="footer"  v-if="activeName != '4' && activeName != '5'">
                        <el-button type="primary" @click="save" :loading="saveLoading">保存</el-button>
                        <el-button @click="cancel">取消</el-button>
                        <!--                    <el-button  @click="changeLock()">锁定</el-button>-->
                    </div>
                </el-form>
            </el-drawer>
        </div>
        <el-dialog
                :visible.sync="showMessage"
                title="提示"
                width="30%"
        >
            <span>当前页面未保存,是否确定退出?</span>
            <template #footer>
      <span class="dialog-footer">
        <el-button @click="showMessage = false">取消</el-button>
        <el-button type="primary" @click="closeEdit">确认</el-button>
      </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
    import { exportData } from "../../js/util/export.js"; //导出表格
    import axios from "axios";
    import store from '../../store/index'
    import http from "../../api/http";
    export default {
        name: "aPicture",
        components: {},
        data() {
            return {
                saveLoading: false,
                labelList:[],
                checkedLabel: [], //标签分类
                xmsl:'0',
                jsgm:'0',
                ztz:0,
                sswtz:0,
                activeRoad: '21',
                isLock: false, //fasle 未锁定  true 已锁定
                dialogTableVisibleMap:false,
                dialogTableVisibleData:{},
                roleName:'',
                activeName:'0',
                required: false,
                innerDrawer: false,
                title:'新增建设单位',
                ruleForm:{},
                chekfr:[{XMZRR:'',XMZRRLXDH:''}],
                confirmXMFR:[{XMZRR:'',XMZRRLXDH:''}],
                XMFRDW:'',
                XMFRDWID:'',
                frCompany:false,
                frCompanyList:[],
                frdw:'',
                frForm:{},
                formInline:{},
                assign: false,
                showAddModel: false,
                lineHeight:500,
                uploadLoading:false,
                detail:{
                    jsxmydys:{pfwj:[]},
                    ptzjcns:{pfwj:[]},
                    stbcpjpf:{pfwj:[]},
                    ghxzyjs:{pfwj:[]},
                    hjyxpjpf:{pfwj:[]},
                    jnpg:{pfwj:[]},
                    shwd:{pfwj:[]},
                    xmjy:{pfwj:[]},
                    gky:{pfwj:[]},
                    cbsj:{pfwj:[]},
                    sgt:{pfwj:[]},
                    zsyd:{pfwj:[]},
                    sgxk:{pfwj:[]},
                    jtb:{pfwj:[]},
                    qt:{pfwj:[]}
                },
                showDetail: false,
                showMessage:false,
                baseUrl: "http://82.156.50.94:8002/",
                gkbz:false,
                singleData:{
                    wlyd:0,
                    nyd:0
                },
                XMMC:'',
                addStatus: true,
                Collapse:['1','2','3'],
                step:['一、工可','二、初步设计','三、施工图设计','四、施工前准备','五、县域国土空间规划'],
                activeProcess:0,
                showEditModel: false,
                confirmSSW:[],
                tableHeight:0, //表格高度
                activeIndex: true, //显隐高级搜索
                /**查询条件 begin*/
                xmmc: "", //项目名称
                xmnd: "", //项目年度
                gllx: "21", //建设类别
                checkedXmxz: [], //项目性质
                checkedJsxz: [], //建设性质
                checkedXmlx: [], //项目类型
                checkedXmlb: [], //项目类别
                checkedJsgm: [], //项目类别
                checkedGk: [], //项目类别
                checkedSgtsj: [], //项目类别
                checkedCbsj: [], //项目类别
                region: [""], //已选中行政区划
                qqgzjz: [], //前期工作进展
                /**查询条件 end*/
                xmxzShow: true, //项目性质查询条件显隐
                jsxzShow: true, //建设性质查询条件显隐
                xmlxShow: true, //项目类型查询条件显隐
                checkList: [],
                checkAllXmlb: false,
                checkAllXmxz: false,
                checkAllJsxz: false,
                checkAllXmlx: false,
                checkAllJsgm: false,
                checkAllGk: false,
                checkAllCbsj: false,
                checkAllSgtsj: false,
                xmxzList: [
                    {
                        value: "续建项目",
                        label: "续建项目",
                        disabled: false,
                    },
                    {
                        value: "新开工项目",
                        label: "新开工项目",
                        disabled: false,
                    },
                    {
                        value: "谋划项目",
                        label: "谋划项目",
                        disabled: false,
                    }
                ],
                jsxzList: [
                    { value: "10", label: "新建", disabled: false },
                    { value: "20", label: "高速公路扩容", disabled: false },
                    { value: "30", label: "升级改造", disabled: false },
                    { value: "40", label: "原级改造", disabled: false },
                    { value: "50", label: "路面改造", disabled: false },
                    { value: "90", label: "改扩建", disabled: false },
                ],
                xmlxList: ["正选项目", "备选项目"],
                jsgmList: [{value: "10", label: "高速", disabled: false},{value: "20", label: "一级", disabled: false},{value: "30", label: "二级", disabled: false},{value: "40", label: "三级", disabled: false},{value: "50", label: "四级", disabled: false},{value: "60", label: "等外", disabled: false}],
                xmlbList: [{value: "21", label: "国家高速公路", disabled: false},
                    {value: "22", label: "普通国道", disabled: false},
                    {value: "23", label: "省级高速公路", disabled: false},
                    {value: "24", label: "普通省道", disabled: false},
                    {value: "25", label: "县道", disabled: false},
                    {value: "26", label: "乡道", disabled: false},
                    {value: "27", label: "村道", disabled: false},
                    {value: "31", label: "航道", disabled: false},
                    {value: "32", label: "码头", disabled: false},
                    {value: "33", label: "防波堤", disabled: false},
                    {value: "34", label: "锚地", disabled: false},
                    {value: "35", label: "通航建筑及枢纽", disabled: false},
                    {value: "90", label: "其他项目", disabled: false},
                ],
                gkList: [{value: "0", label: "未开展", disabled: false},{value: "1", label: "办理中", disabled: false},{value: "2", label: "已办结", disabled: false}],
                cbsjList: [{value: "0", label: "未开展", disabled: false},{value: "1", label: "办理中", disabled: false},{value: "2", label: "已办结", disabled: false}],
                sgtsjList: [{value: "0", label: "未开展", disabled: false},{value: "1", label: "办理中", disabled: false},{value: "2", label: "已办结", disabled: false}],
                currentPage: 1, //当前页
                currentPage1: 1, //当前页
                pageSize: 30, //每页记录数
                pageSize1: 10, //每页记录数
                isIndeterminateXmxz: true,
                isIndeterminateJsgm: true,
                isIndeterminateGk: true,
                isIndeterminateCbsj: true,
                isIndeterminateSgtsj: true,
                isIndeterminateJsxz: true,
                isIndeterminateXmlx: true,
                isIndeterminateXmlb: true,
                SSWSSWGHXMID:'',
                regions: [],
                tableData: [], //请求列表
                total: 0, //请求记录数
                total1: 0, //请求记录数
                totalSqu:0,
                companyData:[],
                searchAll:[],
                chek:[],
                newAdd:false,
                editModel:{},
                showEdit:false,
                qqjzid:'',
                innerDrawerTitle:'',
                gg: '',
                GKWCD:'',
                CBSJWCD:'',
                SGTSJWCD:'',
                SGQZBWCD:'',
                isAdmin: false,
                rowData:{},
                sortData: {
                    field: "XMXH",
                    sort: "asc",
                },
            };
        },
        mounted() {
            var that = this
            let height = this.$refs.init.offsetHeight;
            setTimeout(() => {
                that.tableHeight = document.body.offsetHeight - height - 205;
            }, 100);
            this.getRegion();
            this.search();
            this.http.post('/api/Sys_User/getCurrentUserInfo',{}).then(res=>{
                this.roleName = res.data.roleName
                this.isAdmin = res.data.roleName == '超级管理员' ||  res.data.isMain  == 1 ? true : false
            })
            // this.getCompanyList()
            // this.getFRDW()
        },
        computed:{

        },
        methods: {
            getPageTotal() {
                this.http
                    .post("/api/Plan_high_national_early/GetPageTotal", this.postData())
                    .then((res) => {
                        this.xmsl = res.xmsl;
                        this.jsgm = res.jsgm;
                        this.jsqmqs = res.jsqmqs;
                        this.ztz = res.ztz;
                        this.sswtz = res.sswtz;
                    });
            },
            changeJslb(val){
                console.log(val)
            },
            changeRoad(tab, event){
                console.log(tab)
                this.currentPage = 1
                this.gllx = tab.name
                this.search()
            },
            thousandBitSeparator(num) {
                var reg=/\d{1,3}(?=(\d{3})+$)/g;
                return (num + '').replace(reg, '$&,');
            },
            changeSort(val) {
                if (val.order) {
                    this.sortData = {
                        field: val.prop,
                        sort: val.order == "ascending" ? "asc" : "desc",
                    };
                    if (val.prop == "SZDS") {
                        this.sortData.field = "DSBM";
                    }
                    if (val.prop == "SZX") {
                        this.sortData.field = "QXBM";
                    }
                } else {
                    this.sortData = {
                        field: "XMXH",
                        sort: "asc",
                    };
                }
                this.tableData = [];
                this.search();
            },
            handleClick(tab, event) {
            },
            closeDrawer(){
                this.$refs.detailForm.resetFields()
                this.search()
                // this.$refs.editForm.resetFields()
            },
            cellClass(row){
                if (row.columnIndex === 0) {
                    return 'DisableSelection'
                }
            },
            closeInnerDrawer(){
                this.search()
                setTimeout(()=>{
                    this.tableData.map((item,index)=>{
                        if (item.ID == this.rowData.ID){
                            this.openFlow(item)
                        }
                    })
                },1000)

            },
            accAdd(arg1, arg2) {
                var r1, r2, m;
                try { r1 = arg1.toString().split(".")[1].length; } catch (e) { r1 = 0; }
                try { r2 = arg2.toString().split(".")[1].length; } catch (e) { r2 = 0; }
                m = Math.pow(10, Math.max(r1, r2));
                return (arg1 * m + arg2 * m) / m;
            },
            openFlow(row){
                this.rowData = row
                this.innerDrawerTitle = row.XMMC
                this.innerDrawer = true
                setTimeout(()=>{
                    this.qqjzid = row.ID
                    this.gg =  row.XMSX !=null &&  row.XMSX == '国高'? true : false
                    if ( this.gg){
                        this.GKWCD = Math.round(row.GKWCS / 18 *100) +'%'
                        this.CBSJWCD = Math.round(row.CBSJWCS / 21 *100) +'%'
                    }else{
                        this.GKWCD = Math.round(row.GKWCS / 16*100) +'%'
                        this.CBSJWCD = Math.round(row.CBSJWCS / 19 *100) +'%'
                    }
                    this.SGTSJWCD = Math.round(row.SGTSJWCS / 21 *100) +'%'
                    this.SGQZBWCD = Math.round(row.SGQZBWCS / 5 *100) +'%'
                },500)
            },
            changeXztotal(e,type){
                var reg =  /^(?:[1-9]\d*|0)(?:\.\d+)?$/
                var xz1 = this.detail.xzyj == null || this.detail.xzyj ==  '' || !reg.test(this.detail.xzyj) ? 0 : parseFloat(this.detail.xzyj)
                var xz2 = this.detail.xzgs == null || this.detail.xzgs ==  '' || !reg.test(this.detail.xzgs) ? 0 : parseFloat(this.detail.xzgs)
                var xz3 = this.detail.xzej == null || this.detail.xzej ==  '' || !reg.test(this.detail.xzej) ? 0 : parseFloat(this.detail.xzej)
                var xz4 = this.detail.xzsj == null || this.detail.xzsj ==  '' || !reg.test(this.detail.xzsj) ? 0 : parseFloat(this.detail.xzsj)
                var xz5 = this.detail.xzsi == null || this.detail.xzsi ==  '' || !reg.test(this.detail.xzsi) ? 0 : parseFloat(this.detail.xzsi)
                var xz6 = this.detail.xzsdym == null || this.detail.xzsdym ==  '' || !reg.test(this.detail.xzsdym) ? 0 : parseFloat(this.detail.xzsdym)/1000
                var xz7 = this.detail.xzdw == null || this.detail.xzdw ==  '' || !reg.test(this.detail.xzdw) ? 0 : parseFloat(this.detail.xzdw)
                var xz8 = this.detail.xztdqym == null || this.detail.xztdqym ==  '' || !reg.test(this.detail.xztdqym) ? 0 : parseFloat(this.detail.xztdqym)/1000
                this.detail.xzhj = this.accAdd(this.accAdd(this.accAdd(this.accAdd(this.accAdd(this.accAdd(this.accAdd(xz1,xz2),xz3),xz4),xz5),xz6),xz7),xz8)
                // this.detail.xzhj = (xz1*1000/1000 +xz2*1000/1000 + xz3*1000/1000 +xz4*1000/1000 +xz5*1000/1000 + xz6*1000/1000 + xz7*1000/1000 + xz8*1000/1000)*1000/1000
            },
            changeJstotal(e,type){
                var reg =  /^(?:[1-9]\d*|0)(?:\.\d+)?$/
                var xz1 = this.detail.jsgmyj == null || this.detail.jsgmyj ==  '' || !reg.test(this.detail.jsgmyj) ? 0 : parseFloat(this.detail.jsgmyj)
                var xz2 = this.detail.jsgmej == null || this.detail.jsgmej ==  '' || !reg.test(this.detail.jsgmej) ? 0 : parseFloat(this.detail.jsgmej)
                var xz3 = this.detail.jsgmsj == null || this.detail.jsgmsj ==  '' || !reg.test(this.detail.jsgmsj) ? 0 : parseFloat(this.detail.jsgmsj)
                var xz4 = this.detail.jsgmsdym == null || this.detail.jsgmsdym ==  '' || !reg.test(this.detail.jsgmsdym) ? 0 : parseFloat(this.detail.jsgmsdym)/1000
                var xz5 = this.detail.jsgmtdqym == null || this.detail.jsgmtdqym ==  '' || !reg.test(this.detail.jsgmtdqym) ? 0 : parseFloat(this.detail.jsgmtdqym)/1000
                var xz6 = this.detail.jsgmgs == null || this.detail.jsgmgs ==  '' || !reg.test(this.detail.jsgmgs) ? 0 : parseFloat(this.detail.jsgmgs)
                this.detail.jsgmhj = this.accAdd(this.accAdd(this.accAdd(this.accAdd(this.accAdd(xz1,xz2),xz3),xz4),xz5),xz6)
            },
            //保存
            saveCompany(){
                this.$refs.editForm.validate((valid) => {
                    if (valid) {
                        this.newAdd ?  this.add(this.editModel) : this.update(this.editModel)

                    } else {

                        return false;
                    }
                });
            },
            //新增的
            add(params){
                params.id = '00000000-0000-0000-0000-000000000000'
                this.http.post('/api/Plan_buildcompany/AddBuildcompany?xmlx=0',params).then(res=>{
                    if(res.status){
                        this.$message.success(res.message)
                        this.getFRDW()
                        this.editModel = {}
                        this.$refs.editForm.resetFields()
                        this.showEdit = false
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            //编辑的
            update(params){
                this.http.post('/api/Plan_buildcompany/UpdateBuildcompany?xmlx=0',params).then(res=>{
                    if(res.status){
                        this.$message.success(res.message)
                        this.getFRDW()
                        this.editModel = {}
                        this.$refs.editForm.resetFields()
                        this.showEdit = false
                    }else{
                        this.$message.error(res.message)
                    }
                })

            },
            //删除
            deletedCompany(item){
                this.http.post('/api/Plan_buildcompany/DeleteBuildcompany',item).then(res=>{
                    if(res.status){
                        this.$message.success(res.message)
                        this.getFRDW()
                        this.editModel = {}
                        this.$refs.editForm.resetFields()
                        this.showEdit = false
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            confirmCompany(){
                this.confirmSSW = JSON.parse(JSON.stringify(this.chek))
                if (this.chek.length != 0 ){
                    this.XMMC = this.chek[0].XMMC
                    this.SSWSSWGHXMID =this.chek[0].ID
                }else{
                    this.XMMC = ''
                    this.SSWSSWGHXMID = ''
                }

                this.assign = false
            },
            //编辑
            edit(item){
                this.newAdd = false
                this.title = '修改建设单位'
                this.editModel = item
                // this.editModel = JSON.parse(JSON.stringify(item))
                this.showEdit = true
            },
            addCompany(){
                this.newAdd = true
                this.title = '新增建设单位'
                this.editModel = {}
                this.showEdit = true
            },
            checkZZS  (rule, value, callback)  {
                if (!value) {
                    return callback(new Error('所填项不能为空'));
                }
                // var reg =  /^[+]{0,1}(\d+)$/
                var reg =/^[0-9]\d*(\.\d+)?$/

                console.log(value)
                setTimeout(() => {
                    if (!reg.test(value)) {
                        callback(new Error('请输入数字值'));
                    } else {
                        callback();
                    }
                }, 0);
            },
            checkNum  (rule, value, callback)  {
                if (!value) {
                    callback();
                }
                var reg =  /^\d+(\.\d+)?$/
                setTimeout(() => {
                    if (value && !reg.test(value)) {
                        callback(new Error('请输入数字值'));
                    } else {
                        callback();
                    }
                }, 0);
            },
            checkEdit(rule, value, callback){
                if (this.newAdd == false){
                    callback();
                }else{
                    if (!value) {
                        callback(new Error('请输入当前内容'));
                    }else{
                        callback();
                    }
                }
            },
            checkNull(rule, value, callback){
                // if (this.newAdd == false){
                //
                //     return false
                // }
                if (!value) {
                    callback(new Error('请输入当前内容'));
                }else{
                    callback();
                }
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        alert('submit!');
                    } else {
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            download(url, fileName) {
                //下载导出的文件
                let xmlResquest = new XMLHttpRequest();
                xmlResquest.open("GET", url, true);
                xmlResquest.setRequestHeader("Content-type", "application/json");
                xmlResquest.setRequestHeader(
                    "Authorization",
                    store.getters.getToken()
                );
                let elink = document.createElement("a");
                xmlResquest.responseType = "blob";
                xmlResquest.onload = function(oEvent) {
                    if (xmlResquest.status != 200) {
                        this.$error("下载文件出错了..");
                        return;
                    }
                    let content = xmlResquest.response;
                    elink.download = fileName; //+".xlsx";
                    // elink.style.display = "none";
                    let blob = new Blob([content]);
                    elink.href = URL.createObjectURL(blob);
                    // document.body.appendChild(elink);
                    elink.click();
                    //  document.body.removeChild(elink);
                };
                xmlResquest.send();
            },
            downLoadZip(row){
                var that = this
                this.http.get('/api/Plan_high_national_early/getFilePackage?id='+row.ID,{}).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" + 'Plan_high_national_early' + "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        name+'.zip'
                    );
                })
            },
            showFr(){
                var that = this
                if(!this.isAdmin ){
                    this.$message.warning('暂无此权限')
                    return false
                }
                this.getFRDW()
                this.frCompany = true
                if(this.addStatus){
                    if ( this.confirmXMFR.length != 0 && this.confirmXMFR[0].XMZRR == ''){
                        this.$nextTick(()=>{
                            this.$refs.multipleTable1.clearSelection()
                        })
                    }else{
                        setTimeout(()=> {
                            this.$nextTick(() => {
                                that.$refs.multipleTable1.toggleRowSelection(that.frCompanyList.find((item) => {
                                    if (item.ID == that.XMFRDWID) {
                                        return item
                                    }
                                }))
                            })
                        },1000)

                    }

                }else {
                    if (this.confirmXMFR.length != 0 && this.confirmXMFR[0].XMZRR != ''){
                        setTimeout(()=> {
                            this.$nextTick(() => {
                                that.$refs.multipleTable1.toggleRowSelection(that.frCompanyList.find((item) => {
                                    if (item.ID == that.XMFRDWID) {
                                        return item
                                    }
                                }))
                            }, 1000)
                        })
                    }
                }
            },
            //搜索条件
            searchData(){
                var searchData = {
                    page: this.currentPage1,
                    rows: this.pageSize1,
                    wheres:JSON.stringify([{Name:'XMLX',Value:'Road',DisplayType:'Equal'}]),
                };
                return searchData
            },
            openTable(){
                var that = this
                if(!this.isAdmin ){
                    this.$message.warning('暂无此权限')
                    return false
                }
                this.assign = true
                this.formInline = {}
                this.currentPage1 = 1
                this.getCompanyList()
                // if(this.addStatus && this.confirmSSW.length == 0){
                //     console.log('不行')
                //     this.$nextTick(()=>{
                //         this.$refs.multipleTable.clearSelection()
                //     })
                // }else{
                //     console.log('可以',that.detail,that.companyData)
                //     setTimeout(()=>{
                //      this.$nextTick(()=>{
                //          that.$refs.multipleTable.toggleRowSelection(that.companyData.find((item) => {
                //              if (item.XMMC == that.detail.sswghxmmc){
                //                  return item
                //              }
                //              }),
                //              true);
                //      })
                //  },1000)
                //
                //  // that.$refs.multipleTable.toggleRowSelection(that.chek[0],true);
                //
                // }

            },
            checkSelectable(row) {
                if (this.formInline.dwmc && row.dwmc.indexOf(this.formInline.dwmc) < 0) {
                    return false;
                } else {
                    return true;
                }
            },
            checkSelectable1(row) {
                if(!row.XMLXR){
                    row.XMLXR = ''
                }
                if (this.frForm.dwmc && row.DWMC.indexOf(this.frForm.dwmc) < 0 ||
                    this.frForm.xmzrr && row.XMZRR.indexOf(this.frForm.xmzrr) < 0 ||
                    this.frForm.xmlxr && row.XMLXR.indexOf(this.frForm.xmlxr) < 0 ) {
                    return false;
                } else {
                    return true;
                }
            },
            confirmChoose(){

            },
            tableRowClassName(row, index) {
                return this.checkSelectable1(row.row) ? "" : "hidden";
            },
            //选择建设单位
            selectCompany(val){
                var that = this
                if(val.length > 1){
                    this.$refs.multipleTable.clearSelection()
                    this.$refs.multipleTable.toggleRowSelection(val.pop())
                }else{
                    this.chek = val;

                }
            },
            conformXmlxr(){
                if (this.chekfr.length != 0){

                    this.confirmXMFR =  this.chekfr
                    this.XMFRDW = this.confirmXMFR[0].DWMC
                    this.XMFRDWID = this.confirmXMFR[0].ID
                }else{
                    this.confirmXMFR=[{XMZRR:'',XMZRRLXDH:''}],

                        this.XMFRDW = ''
                    this.XMFRDWID = ''
                }
                this.frCompany = false
            },
            async selectCompany1(val){
                if(val.length > 1){
                    this.$refs.multipleTable1.clearSelection()
                    this.$refs.multipleTable1.toggleRowSelection(val.pop())
                }else{
                    this.chekfr = val;
                }
            },
            //十四五项目列表
            getXMLB(){
                this.http.post('/api/V_plan_high_national_early/GetPageData',{}).then(res=>{
                })
            },
            //获取法人单位    /api/Plan_buildcompany/getBuildCompanyList
            getFRDW(){
                var that =this
                this.frCompanyList = []
                this.http.post('/api/Plan_buildcompany/GetPageData', {rows:1000}).then(res=>{
                    console.log(res)
                    res.rows.map((item,index)=>{
                        // if (item.XMLX == 'Road'){
                        this.frCompanyList.push(item)
                        // }
                    })

                    if(this.addStatus){
                        if ( this.confirmXMFR.length != 0 && this.confirmXMFR[0].XMZRR == ''){
                            this.$nextTick(()=>{
                                this.$refs.multipleTable1.clearSelection()
                            })
                        }

                    }else {
                        if (this.confirmXMFR.length != 0 && this.confirmXMFR[0].XMZRR != ''){
                            setTimeout(()=> {
                                this.$nextTick(() => {
                                    that.$refs.multipleTable1.toggleRowSelection(that.frCompanyList.find((item) => {
                                        if (item.ID == that.XMFRDWID) {
                                            return item
                                        }
                                    }))
                                }, 1000)
                            })
                        }
                    }
                })
                console.log(this.frCompanyList)
            },
            searchCompanyList(){
                this.getCompanyList(1)
            },
            getCompanyList(page){
                var that = this
                // /api/Plan_road/GetPageData   /api/Plan_high_national_early/queryPlanRoad
                this.http.post('/api/Plan_high_national_early/queryPlanRoad',  this.postData1(page)).then(res=> {
                    this.companyData = res.rows
                    this.total1 = res.total
                    this.$nextTick(() => {
                        that.$refs.multipleTable.toggleRowSelection(that.companyData.find((item) => {
                                if (item.XMMC == that.detail.sswghxmmc){
                                    return item
                                }
                            }),
                            true);


                        // this.companyData.forEach((row) => {
                        //     this.$refs.multipleTable.toggleRowSelection(this.companyData.find((item) => {
                        //             return item.IsSelf == true;
                        //         }),
                        //         true);
                        // });

                        // this.companyData.map((item,index)=>{
                        //     if (item.IsSelf === true){
                        //         console.log(212121)
                        //         that.$refs.multipleTable.toggleRowSelection(item,true);
                        //     }
                        // })
                    })
                })
            },
            addRoad(){
                var that = this
                this.addStatus = true
                this.confirmSSW = []
                this.currentPage1 = 1
                this.showAddModel = true
                this.totalSqu = 0
                this.activeName = '0'
                this.detail = {
                    jsxmydys:{pfwj:[]},
                    ptzjcns:{pfwj:[]},
                    stbcpjpf:{pfwj:[]},
                    ghxzyjs:{pfwj:[]},
                    hjyxpjpf:{pfwj:[]},
                    jnpg:{pfwj:[]},
                    shwd:{pfwj:[]},
                    xmjy:{pfwj:[]},
                    gky:{pfwj:[]},
                    cbsj:{pfwj:[]},
                    sgt:{pfwj:[]},
                    zsyd:{pfwj:[]},
                    sgxk:{pfwj:[]},
                    jtb:{pfwj:[]},
                    qt:{pfwj:[]}
                }
                this.XMFRDW =''
                this.XMFRDWID =''
                this.XMMC = ''
                this.SSWSSWGHXMID = ''
                this.chekfr =[{XMZRR:'',XMZRRLXDH:''}]
                this.confirmXMFR =[{XMZRR:'',XMZRRLXDH:''}]
                this.chek = []

            },
            //退出编辑
            closeEdit(){
                this.showMessage = false
                this.showEditModel = false
                this.showAddModel = false
                this.detail = {
                    jsxmydys:{pfwj:[]},
                    ptzjcns:{pfwj:[]},
                    stbcpjpf:{pfwj:[]},
                    ghxzyjs:{pfwj:[]},
                    hjyxpjpf:{pfwj:[]},
                    jnpg:{pfwj:[]},
                    shwd:{pfwj:[]},
                    xmjy:{pfwj:[]},
                    gky:{pfwj:[]},
                    cbsj:{pfwj:[]},
                    sgt:{pfwj:[]},
                    zsyd:{pfwj:[]},
                    sgxk:{pfwj:[]},
                    jtb:{pfwj:[]},
                    qt:{pfwj:[]}
                }
            },
            //删除图片
            deleted(index,type){
                this.detail[type].pfwj.splice(index,1)
            },
            activeChange(e){
            },
            changeTotal(val,type){
                var that = this
                var reg =  /^(?:[1-9]\d*|0)(?:\.\d+)?$/
                if (val && !reg.test(val)) {
                    return false
                }
                if (type == 'nyd'){
                    if(this.detail.ydwlyd != '' && this.detail.ydwlyd != null){
                        this.totalSqu = val == '' ? that.accAdd(0,parseFloat(this.detail.ydwlyd))  :  that.accAdd( parseFloat(val) ,  parseFloat(this.detail.ydwlyd))
                    }else{
                        this.totalSqu = val == '' ? 0 : parseFloat(val)*1000/1000
                    }
                }else{
                    if(this.detail.ydnyd != '' && this.detail.ydnyd != null){
                        this.totalSqu =  val == '' ? that.accAdd(0 , parseFloat(this.detail.ydnyd)) : that.accAdd(parseFloat(val)  , parseFloat(this.detail.ydnyd))
                    }else{
                        this.totalSqu = val == '' ? 0: parseFloat(val)
                    }
                }

            },
            setTimes(time) {
                if (!time) return time;
                let dt = new Date(time);
                let yyyy = dt.getFullYear();
                let MM = (dt.getMonth() + 1).toString().padStart(2, "0");
                let dd = dt.getDate().toString().padStart(2, "0");
                return yyyy + "-" + MM + "-" + dd;
            },
            save(){
                this.$refs.detailForm.validate((valid) => {
                    this.saveLoading = true
                    if (valid) {
                        var params = JSON.parse(JSON.stringify(this.detail))
                        // var checkList = []
                        // params.jsxz = params.jsxz.join(";")
                        //
                        // params.sswghxmmc = this.XMMC
                        // params.sswghxmid = this.SSWSSWGHXMID
                        // params.xmfrdw = this.XMFRDW
                        // params.xmfrdwid = this.XMFRDWID
                        // params.isLock = this.isLock
                        // if(this.chekfr.length != 0){
                        //     params.qqgzfzr = this.chekfr[0].XMZRR
                        //     params.lxdh = this.chekfr[0].XMZRRLXDH
                        // }
                        if (this.addStatus){
                            params.id = '00000000-0000-0000-0000-000000000000'
                            this.http.post('/api/Plan_high_national_early/AddPlanHighNationalEarly',params).then(res=>{
                                this.saveLoading = false
                                if(res.status){
                                    // this.showAddModel = false
                                    this.qqjzid = res.data.id
                                    this.addStatus = false
                                    this.search()
                                    this.$message.success(res.message)
                                }else{
                                    this.$message.error(res.message)
                                }


                            })
                        }else{
                            this.http.post('/api/Project_list/UpdateProject',params).then(res=>{
                                this.saveLoading = false
                                if(res.status){
                                    this.showAddModel = false
                                    this.search()
                                    this.$message.success(res.message)
                                }else{
                                    this.$message.error(res.message)
                                }


                            })
                        }
                    } else {
                        this.saveLoading = false
                        return false;
                    }
                });
            },
            cancel(){
                this.showMessage = true
                this.showEdit = false
                this.$refs.editForm.resetFields()

            },
            onSubmit(e, type) {
                this.uploadLoading = true
                let file = e.target.files;
                let form = new FormData();
                let arrs = [];
                file.forEach((element) => {
                    form.append("fileInput", element);
                    arrs.push(element.name);
                });
                this.http.post("/api/Plan_high_national_early/upload", form)
                    .then((res) => {
                        this.uploadLoading = false
                        arrs.forEach((v) => {
                            // this.details[type].push(res.data + v);
                            this.detail[type].pfwj.push({url:res.data, name:v})

                        });
                        // this.detail[type].pfwj.push({url:res.data, name:file[0].name})
                    });

            },
            changeCollapse(val){
            },
            chechfieldxmlx() {
                return this.gllx == "普通省道" ? false : true;
            },
            chechfieldxmlb() {
                return this.gllx == "普通省道" ? false : true;
            },
            checkfieldjsxz() {
                return this.gllx == "高速公路" ? true : false;
            },
            checkfieldxmxz(e) {
                var arr = [];
                if (this.gllx == "高速公路") {
                    arr = [
                        "“十三五”续建项目",
                        "2021年新开工项目",
                        "“十四五”其他项目",
                        "“十四五”谋划项目",
                    ];
                    return arr.indexOf(e) >= 0 ? false : true;
                }
                if (this.gllx == "普通国道") {
                    arr = [
                        "“十三五”续建项目",
                        "“十四五”新开工项目",
                        "谋划项目",
                        "“十四五”谋划项目",
                    ];
                    return arr.indexOf(e) >= 0 ? false : true;
                }
                if (this.gllx == "普通省道") {
                    arr = [
                        "“十三五”续建项目",
                        "2021年已安排计划项目",
                        "2022年建设项目",
                        "2023年建设项目",
                        "2024-2025年建设项目",
                    ];
                    return arr.indexOf(e) >= 0 ? false : true;
                }
            },
            closeDetail(){
                this.showDetail = false
                this.detail = {}

            },
            openDetailsView(row) {
                this.showDetail = true
                this.detail = row
                this.detail.gkbzwj = this.detail.gkbzwj == null || this.detail.gkbzwj.length === 0 ? [] :JSON.parse(this.detail.gkbzwj)
                this.detail.gkpsyj = this.detail.gkpsyj == null|| this.detail.gkbzwj.gkpsyj === 0  ? [] :JSON.parse(this.detail.gkpsyj)
                this.detail.gkpfwj = this.detail.gkpfwj == null || this.detail.gkbzwj.gkpfwj === 0 ? [] :JSON.parse(this.detail.gkpfwj)
                this.detail.cbsjbzwj = this.detail.cbsjbzwj == null|| this.detail.gkbzwj.cbsjbzwj === 0  ? [] :JSON.parse(this.detail.cbsjbzwj)
                this.detail.cbsjpswj = this.detail.cbsjpswj == null|| this.detail.gkbzwj.cbsjpswj === 0  ? [] :JSON.parse(this.detail.cbsjpswj)
                this.detail.cbsjpfwj = this.detail.cbsjpfwj == null || this.detail.gkbzwj.cbsjpfwj === 0 ? [] :JSON.parse(this.detail.cbsjpfwj)
                this.detail.sgtsjbzwj = this.detail.sgtsjbzwj == null|| this.detail.gkbzwj.sgtsjbzwj === 0  ? [] :JSON.parse(this.detail.sgtsjbzwj)
                this.detail.sgtsjpswj = this.detail.sgtsjpswj == null || this.detail.gkbzwj.sgtsjpswj === 0 ? [] :JSON.parse(this.detail.sgtsjpswj)
                this.detail.sgtsjpfwj = this.detail.sgtsjpfwj == null|| this.detail.gkbzwj.sgtsjpfwj === 0  ? [] :JSON.parse(this.detail.sgtsjpfwj)
                this.detail.sgqzbydpfwj = this.detail.sgqzbydpfwj == null || this.detail.gkbzwj.sgqzbydpfwj === 0 ? [] :JSON.parse(this.detail.sgqzbydpfwj)
                this.detail.sgqzbsgxdpfwj = this.detail.sgqzbsgxdpfwj == null || this.detail.gkbzwj.sgqzbsgxdpfwj === 0 ? [] :JSON.parse(this.detail.sgqzbsgxdpfwj)
                this.detail.xygtkjghwj = this.detail.xygtkjghwj == null|| this.detail.gkbzwj.xygtkjghwj === 0  ? [] :JSON.parse(this.detail.xygtkjghwj)
            },
            openDetailsEdit(row) {
                var that = this
                this.showAddModel = true
                this.activeName = '0'
                // this.$refs.detailForm.resetFields();
                this.addStatus = false
                // setTimeout(()=>{
                //     this.qqjzid = row.ID
                //     this.gg =  row.XMSX !=null &&  row.XMSX == '国高'? true : false
                // },500)
                this.http.post('/api/Project_list/getItemById?id='+row.ID,{}).then(res=>{
                    console.log(res)
                    this.detail = res
                    // this.detail.jsxz = this.detail.jsxz ? this.detail.jsxz.split(';') : []
                    // this.isLock = res.data.isLock
                    // this.totalSqu = res.data.ydhj
                    // this.XMMC = res.data.sswghxmmc
                    // this.SSWSSWGHXMID = res.data.sswghxmid
                    // this.XMFRDW = res.data.xmfrdw
                    // this.XMFRDWID = res.data.xmfrdwid
                    // this.chekfr = [{ID:res.data.xmfrdwid,DWMC: res.data.xmfrdw,XMZRR: res.data.qqgzfzr,XMZRRLXDH:res.data.lxdh}]
                    // this.confirmXMFR = [{ID:res.data.xmfrdwid,DWMC: res.data.xmfrdw,XMZRR: res.data.qqgzfzr,XMZRRLXDH:res.data.lxdh}]

                })
            },
            exportData() {
                var that = this
                this.http.post('/api/Plan_high_national_early/templateWrite',this.postData()).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" + 'Plan_high_national_early' + "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var timestamp = new Date().getTime();
                    // var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        '前期项目表'+timestamp+'.xlsx'
                    );
                })
                // exportData("/api/Plan_high_national_early/templateWrite", this.postData());
            },
            exportData1() {
                var that = this
                this.http.post('/api/Plan_high_national_early/EarlyTemplateWrite',this.postData()).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" + 'Plan_high_national_early' + "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var timestamp = new Date().getTime();
                    // var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        '前期进展统计表'+timestamp+'.xlsx'
                    );
                })
                // exportData("/api/Plan_high_national_early/templateWrite", this.postData());
            },
            getRegion() {
                var postData = { SZDS: "", SZX: "" };
                this.http
                    .post("/api/Base_area/getAdminDivTree", postData)
                    .then((res) => {
                        // res.map((r) => {
                        //     console.log(r)
                        //     r.value = r.label;
                        //     r.children.map((rr) => {
                        //         rr.value = rr.label;
                        //     });
                        // });
                        this.regions = res;

                    });
            },
            //查询
            search() {
                this.$refs.table.bodyWrapper.scrollTop = 0; //滚动条回顶部
                this.getPageTotal(); //查汇总
                this.http
                    .post(
                        "/api/Project_list/GetPageData",
                        this.postData(),
                        "正在请求数据，请稍候..."
                    )
                    .then((res) => {
                        this.regions =  [
                            {
                                value: "",
                                label: "黑龙江省",
                            },
                        ]
                        this.getRegion()
                        if (res.status == 0) {
                            this.total = res.total;
                            this.tableData = res.rows;
                        }
                    });
            },
            postData(gllx) {
                var szds = "";
                var ssx = "";
                var reg = JSON.parse(JSON.stringify(this.region))
                console.log(reg)
                if (reg.length > 0) {
                    ssx = reg[0];
                    if (reg.length > 1) {
                        ssx = reg[1];
                    }
                }

                //项目名称
                var query_xmmc = {
                    Name: "XMMC",
                    Value: this.xmmc,
                    DisplayType: "like",
                };
                //项目gx
                var query_gllx = {
                    Name: "XMLB",
                    Value: this.gllx,
                    DisplayType: "Equal",
                };
                //法人单位
                var query_frdw = {
                    Name: "XMFRDW",
                    Value: this.frdw,
                    DisplayType: "like",
                };
                //行政区划代码
                var query_ssx = {
                    Name: "XZQHDM",
                    Value: ssx,
                    DisplayType: "Equal",
                };
                //项目类别
                var xmlbArray = [];
                this.checkedXmlb.forEach((element) => {
                    xmlbArray.push(element);
                });
                var query_xmlb = {
                    Name: "XMLB",
                    Value: xmlbArray.join(","),
                    DisplayType: "checkbox",
                };
                //建设性质
                var jsxzArray = [];
                this.checkedJsxz.forEach((element) => {
                    jsxzArray.push(element);
                });
                var query_jsxz = {
                    Name: "JSXZ",
                    Value: jsxzArray.join(","),
                    DisplayType: "checkbox",
                };
                //项目性质
                var xmxzArray = [];
                this.checkedXmxz.forEach((element) => {
                    xmxzArray.push(element);
                });
                var query_xmxz = {
                    Name: "XMXZ",
                    Value: xmxzArray.join(","),
                    DisplayType: "checkbox",
                };
                //建设规模
                var jsgmArray = [];
                this.checkedJsgm.forEach((element) => {
                    jsgmArray.push(element);
                });
                var query_jsgm = {
                    Name: "JSDJ",
                    Value: jsgmArray.join(","),
                    DisplayType: "checkbox",
                };
//工可
                var gk = [];
                this.checkedGk.forEach((element) => {
                    gk.push(element);
                });
                var query_gk = {
                    Name: "GKBLZT",
                    Value: gk.join(","),
                    DisplayType: "checkbox",
                };
                var query_xmbq = {
                    Name: "TagId",
                    Value: this.checkedLabel.join(","),
                    DisplayType: "checkbox",
                };
                var cbsj = []
                this.checkedCbsj.forEach((element) => {
                    cbsj.push(element);
                });
                var query_cbsj = {
                    Name: "CBSJBLZT",
                    Value: cbsj.join(","),
                    DisplayType: "checkbox",
                };

                var sgt = []
                this.checkedSgtsj.forEach((element) => {
                    sgt.push(element);
                });
                var query_sgt = {
                    Name: "SGTBLZT",
                    Value: sgt.join(","),
                    DisplayType: "checkbox",
                };
                var postData = {
                    page: this.currentPage,
                    rows: this.pageSize,
                    Sort: "",
                    Order: "",
                    wheres: JSON.stringify([
                        query_jsxz,
                        query_gllx,
                        query_xmlb,
                        query_xmmc,
                        query_ssx,

                        query_jsgm

                    ]),
                };
                return postData;
            },
            postData1(page){

                //项目名称
                var query_xmmc = {
                    Name: "XMMC",
                    Value: this.formInline.XMMC,
                    DisplayType: "like",
                };

                if(page){
                    this.currentPage1 = page
                }
                var postData = {
                    page: this.currentPage1,
                    rows: this.pageSize1,
                    Sort: "DSBM",
                    Order: "asc",
                    wheres: JSON.stringify([
                        query_xmmc
                    ]),
                };
                if(!this.addStatus){
                    postData.value = this.detail.id
                }
                return postData;
            },
            clearCondition() {
                this.region = [""];
                this.xmmc = "";
                this.frdw = "";
                this.xmnd = "";
                this.qqgzjz = [];
                this.checkedXmlb = [];
                this.checkedJsgm = []
                this.checkedGk = [];
                this.checkedCbsj = [];
                this.checkedSgtsj = [];
                this.checkedXmxz = [];
                this.checkedJsxz = [];
            },
            // handleChange(value) {
            //     this.checkedXmxz = [];
            //     this.search();
            // },
            handleCheckAllJsxzChange(val) {
                this.checkedJsxz = val ? this.jsxzList : [];
                this.isIndeterminateJsxz = false;
            },
            //项目类别
            handleCheckAllXmlbChange(val){
                this.checkedXmlb = val ? this.xmlbList : [];
                this.isIndeterminateXmlb = false;
            },
            //建设规模
            handleCheckAllJsgmChange(val){
                this.checkedJsgm = val ? this.jsgmList : [];
                this.isIndeterminateJsgm = false;
            },
            //工可
            handleCheckAllGkChange(val){
                this.checkedGk = val ? this.gkList : [];
                this.isIndeterminateGk = false;
            },
            //初步设计
            handleCheckAllCbsjChange(val){
                this.checkedCbsj = val ? this.cbsjList : [];
                this.isIndeterminateCbsj = false;
            },
            //施工图设计
            handleCheckAllSgtsjChange(val){
                this.checkedSgtsj = val ? this.sgtsjList : [];
                this.isIndeterminateSgtsj = false;
            },
            handleCheckAllXmlxChange(val) {
                if (this.gllx == "普通省道") {
                    this.checkedXmlx = val ? this.xmlxList : [];
                }
                this.isIndeterminateXmlx = false;
            },
            handleCheckedXmxzChange(value) {
                let checkedCount = value.length;
                this.checkAllXmxz = checkedCount === this.xmxzList.length;
                this.isIndeterminateXmxz =
                    checkedCount > 0 && checkedCount < this.xmxzList.length;
            },
            handleCheckedJsxzChange(value) {
                let checkedCount = value.length;
                this.checkAllJsxz = checkedCount === this.jsxzList.length;
                this.isIndeterminateJsxz =
                    checkedCount > 0 && checkedCount < this.jsxzList.length;
            },
            handleCheckedXmlbChange(value){
                let checkedCount = value.length;
                this.checkAllXmlb = checkedCount === this.xmlbList.length;
                this.isIndeterminateXmlb =
                    checkedCount > 0 && checkedCount < this.xmlbList.length;
            },
            handleCheckedJsgmChange(value){
                let checkedCount = value.length;
                this.checkAllJsgm = checkedCount === this.jsgmList.length;
                this.isIndeterminateJsgm =
                    checkedCount > 0 && checkedCount < this.jsgmList.length;
            },
            handleCheckedXmlxChange(value) {
                let checkedCount = value.length;
                this.checkAllXmlx = checkedCount === this.xmlxList.length;
                this.isIndeterminateXmlx =
                    checkedCount > 0 && checkedCount < this.xmlxList.length;
            },
            handleCheckedGkChange(value) {
                let checkedCount = value.length;
                this.checkAllGk = checkedCount === this.gkList.length;
                this.isIndeterminateGk =
                    checkedCount > 0 && checkedCount < this.gkList.length;
            },
            handleCheckedCbsjChange(value) {
                let checkedCount = value.length;
                this.checkAllCbsj = checkedCount === this.cbsjList.length;
                this.isIndeterminateCbsj =
                    checkedCount > 0 && checkedCount < this.cbsjList.length;
            },
            handleCheckedSgtsjChange(value) {
                let checkedCount = value.length;
                this.checkAllSgtsj = checkedCount === this.sgtsjList.length;
                this.isIndeterminateSgtsj =
                    checkedCount > 0 && checkedCount < this.sgtsjList.length;
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.search();
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.search();
            },
            handleSizeChange1(val) {
                this.pageSize1 = val;
                this.getCompanyList()
            },
            handleCurrentChange1(val) {
                this.currentPage1 = val;
                this.getCompanyList()

            },
        },
        watch: {
            activeIndex(v) {
                if (v) {
                    this.tableHeight = document.body.offsetHeight - 450;
                } else {
                    this.tableHeight = document.body.offsetHeight - 245;
                }
            },
        },
    };
</script>
<style>
    .hidden {
        display: none ;
    }
</style>
<style lang="less" scoped>
    .roadFront{
        font-family: "Microsoft YaHei 微软雅黑";
    }
    /deep/.el-step__title.is-process{
        color: #409EFF !important;
        border-color: #409EFF !important;
    }
    /deep/.el-collapse-item__header{
        height: 32px;
        padding:0 15px;
        background: #F2F8FE !important;
    }
    .title{
        padding: 0 16px;
        min-width: 98px;
        height: 32px;
        background: #409EFF;
        opacity: 1;
        line-height: 32px;
        text-align: center;
        color: #fff;
    }
    .file{
        margin-right: 10px;
        display: inline-block;
        margin-top: 5px;
        padding: 0 6px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #B3D8FF;
        opacity: 1;
        border-radius: 4px;
        color: #409EFF;
        a{
            height: 32px;
            line-height: 32px;
        }
    }
    .roadFront{
        /deep/.el-collapse{
            margin-top: 20px;
        }
        /deep/.el-form--inline .el-form-item{
            margin-right: 20px;
        }
        /*/deep/.el-collapse-item__content {*/
        /*    padding:10px 15px 20px 15px  !important;*/
        /*}*/
        .editModal{
            /deep/.el-input__inner{
                width: 330px;
                height: 35px;
                line-height: 35px;
            }

        }
        .dwmcWrap{
            /deep/.el-input__inner{
                width: 330px;
            }

        }
        /deep/.box-content .el-form-item__label{
            margin-right: 0px;
            text-align: left;
            color: #034761;
            background:unset;
        }
        /deep/ .formBox .el-input--suffix{
            width: 160px;
        }
        .col{
            /deep/.el-form-item__label{
                background: #F2F8FE;
                color: #78818E;
                line-height: 30px;
                float: unset;
            }
        }
        /deep/.el-form-item__label{
            margin-right: 4px;
            color: #000;
            text-align: center;
            background: #FAFAFA;
        }
        .search{
            /deep/.el-form-item__label{
                background: unset;
            }
        }
    }

    .footer{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 47px;
        background: #F8F8F8;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .editWrap{
        /deep/.el-drawer__body{
            padding: 12px 15px 0px;
            /*padding-bottom: 60px;*/
        }
        .el-row{
            margin-bottom: 4px;
        }
    }

    /deep/.el-form-item__error--inline {
        position: relative;
        top: auto;
        display: block;
        left: auto;
        margin-left: 10px;
    }
    /deep/.el-step.is-simple:not(:last-of-type) .el-step__title{
        max-width: 70%;
    }
    .bigTitle{
        width: 50%;
        margin: 24px 0;
        /*border-bottom: 1px solid #B3D8FF;*/
        span{
            color: #333;
            font-weight: 600;
            padding: 8px 12px;
            height: 28px;
            background: #B3D8FF;
            opacity: 1;
            border-radius: 0px 14px 0px 0px;
        }

    }
    a{
        border: none;
    }
    .dwmcWrap{
        cursor: pointer;
        width: 330px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border:1px solid #DCDFE6;
        border-radius: 5px;
        .dwmc{
            padding-left: 15px;
            text-align: left;
            width: 310px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 35px;
            background: #ffffff;
            border-radius: 5px 0 0 5px;
            line-height: 35px;

        }
        .plus{
            border-left: 1px solid #f9f9f9;
            width: 20px;
            height: 35px;
            background: #fafafa;
            border-radius: 0 5px 5px 0;
            i{
                font-size: 16px;
            }
        }
    }
    // 隐藏全选框
    /deep/ .DisableSelection > .cell {
        display: none !important;
    }
    /deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
        color: #fff;
        background: #409eff;
    }
    /deep/.el-dialog{
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .col{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 60px;
        .col-1{
            padding:10px 20px 10px 20px;
            width: 330px;
            background: #F2F8FE;
            min-height: 710px;
            border-radius: 5px;
        }
    }
    .deliver{
        margin: 15px 0;
        width: 100%;
        height: 1px;
        border-bottom: 1px dotted #999999;
    }
    .el-divider__text{
        background: #F2F8FE;
    }
</style>
